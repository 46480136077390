import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, vModelSelect as _vModelSelect, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "card border-0",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "card-header border-0" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = {
  class: "d-flex align-items-center position-relative my-1",
  style: {"width":"1400px"}
}
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-2 mt-2" }
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 0,
  class: "dropdown ms-16"
}
const _hoisted_8 = { class: "dropdown-content" }
const _hoisted_9 = { class: "card-body pt-0" }
const _hoisted_10 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_11 = ["value", "onChange", "checked"]
const _hoisted_12 = { class: "d-flex justify-content-start" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 0,
  href: "#",
  class: "text-gray-600 text-hover-primary"
}
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 0,
  href: "#",
  class: "text-muted-600 pe-none"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = {
  class: "modal fade",
  id: "exampleModal",
  tabindex: "-1",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_19 = { class: "modal-dialog" }
const _hoisted_20 = { class: "modal-content" }
const _hoisted_21 = { class: "modal-body p-5" }
const _hoisted_22 = { class: "" }
const _hoisted_23 = { class: "modal-footer" }
const _hoisted_24 = {
  class: "modal",
  "data-bs-backdrop": "static",
  "data-bs-keyboard": "false",
  id: "addroutingmodal",
  ref: "addroutingmodalRef",
  tabindex: "-1"
}
const _hoisted_25 = { class: "modal-dialog modal-dialog-centered mw-1000px" }
const _hoisted_26 = { class: "modal-content roundeda" }
const _hoisted_27 = {
  class: "modal-header bg-primary",
  id: "kt_modal_create_api_key_header"
}
const _hoisted_28 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_29 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_create_api_key_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_create_api_key_header",
  "data-kt-scroll-wrappers": "#kt_modal_create_api_key_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "col-md-6" }
const _hoisted_32 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_33 = { class: "col-md-6" }
const _hoisted_34 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_35 = { class: "col-md-6" }
const _hoisted_36 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_37 = { class: "col-md-6" }
const _hoisted_38 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_39 = { class: "col-md-6" }
const _hoisted_40 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_41 = { class: "col-md-6" }
const _hoisted_42 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_43 = { class: "modal-footer flex-center" }
const _hoisted_44 = {
  ref: "submitButtonRef",
  type: "submit",
  id: "kt_modal_create_api_key_submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Card"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Card header"),
      _cache[29] || (_cache[29] = _createElementVNode("h4", { class: "text-primary mx-10" }, "Bins", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Card title"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Search"),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
                "data-kt-subscription-table-filter": "search",
                class: "form-control border mr-3 border-primary form-control-solid w-250px ps-14",
                placeholder: "Search Bin"
              }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
                [_vModelText, _ctx.search]
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("select", {
                style: {"width":"300px"},
                class: "form-select border ms-5 border-primary form-select-solid",
                "data-control": "select2",
                onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCustomerChange && _ctx.onCustomerChange(...args))),
                "data-dropdown-parent": "#kt_modal_1",
                "data-placeholder": "Select an option",
                "data-allow-clear": "true"
              }, [
                _cache[21] || (_cache[21] = _createElementVNode("option", { value: "" }, "All", -1 /* HOISTED */)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: i,
                    value: customer.id
                  }, _toDisplayString(customer.name + " (ID: " + customer.id + ")"), 9 /* TEXT, PROPS */, _hoisted_6))
                }), 128 /* KEYED_FRAGMENT */))
              ], 32 /* NEED_HYDRATION */)
            ]),
            (_ctx.permissions.includes('bins-actions'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _cache[24] || (_cache[24] = _createElementVNode("button", {
                    class: "dropbtn",
                    style: {"height":"50px","border-radius":"8px"}
                  }, [
                    _createTextVNode(" Actions "),
                    _createElementVNode("i", { class: "bi bi-caret-down-fill text-white" })
                  ], -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Depthmapoption()))
                    }, "Depth Map"),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Mobilityoption()))
                    }, "Mobility"),
                    (_ctx.bin_ids.length)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          href: "#",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.BinDimensionsin()))
                        }, "Bin Dimensions"))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.BinWasteTyep()))
                    }, "Waste Type"),
                    (_ctx.bin_ids.length)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          href: "#",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.BinLocation()))
                        }, "Location"))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.BinNotifications()))
                    }, "Notifications"),
                    _cache[22] || (_cache[22] = _createElementVNode("a", {
                      href: "#",
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#exampleModal"
                    }, "Off Set", -1 /* HOISTED */)),
                    _cache[23] || (_cache[23] = _createElementVNode("a", {
                      href: "#",
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#addroutingmodal"
                    }, "Routing Configuration", -1 /* HOISTED */)),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.BinFull_At && _ctx.BinFull_At(...args)))
                    }, "Full At"),
                    _createElementVNode("a", {
                      href: "#",
                      class: _normalizeClass({ disabled: _ctx.bin_ids.length === 0 }),
                      onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.Zones && _ctx.Zones(...args)))
                    }, "Zone", 2 /* CLASS */)
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _createCommentVNode("end::Search")
        ]),
        _createCommentVNode("begin::Card title"),
        _createCommentVNode("begin::Card toolbar"),
        _cache[25] || (_cache[25] = _createStaticVNode("<div class=\"card-toolbar\" data-v-3ab77cd4><!--begin::Toolbar--><!--begin::Group actions--><div class=\"d-flex justify-content-end align-items-center d-none\" data-kt-subscription-table-toolbar=\"selected\" data-v-3ab77cd4><div class=\"fw-bolder me-5\" data-v-3ab77cd4><span class=\"me-2\" data-kt-subscription-table-select=\"selected_count\" data-v-3ab77cd4></span> Selected </div><button type=\"button\" class=\"btn btn-danger\" data-kt-subscription-table-select=\"delete_selected\" data-v-3ab77cd4> Delete Selected </button></div><!--end::Group actions--></div>", 1)),
        _createCommentVNode("end::Card toolbar")
      ]),
      _createCommentVNode("end::Card header"),
      _createCommentVNode("begin::Card body"),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_KTDatatable, {
          "table-data": _ctx.bindata,
          total: _ctx.total,
          "table-header": _ctx.headerConfig,
          "enable-items-per-page-dropdown": true,
          loading: _ctx.loading,
          rowsPerPage: _ctx.pagination.page_size,
          onCurrentChange: _ctx.currentChange,
          onItemsPerPageChange: _ctx.itemsPerPageChange,
          onSelectAll: _ctx.selectAll
        }, {
          "cell-checkbox": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("input", {
                class: "form-check-input border border-primary",
                type: "checkbox",
                value: bin.bin_id,
                onChange: ($event: any) => (_ctx.selectBins(bin)),
                checked: _ctx.isChecked(bin.bin_id)
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_11),
              _createCommentVNode(" //:checked=\"isChecked(bin.bin_id)\" ")
            ])
          ]),
          "cell-variant_id": _withCtx(({ row: bin }) => [
            _createElementVNode("div", null, _toDisplayString(bin.variant_id), 1 /* TEXT */)
          ]),
          "cell-se_account_id": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(bin.se_account_id
              ? `${_ctx.customers.find((c) => c.id == bin.se_account_id).name}`
              : "N/A"), 1 /* TEXT */)
          ]),
          "cell-last_seen": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(_ctx.dateTime(bin.last_seen)), 1 /* TEXT */)
          ]),
          "cell-battery_level": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(bin.battery_level), 1 /* TEXT */)
          ]),
          "cell-bin_height": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(bin.bin_height), 1 /* TEXT */)
          ]),
          "cell-temperature": _withCtx(({ row: bin }) => [
            _createTextVNode(_toDisplayString(bin.temperature), 1 /* TEXT */)
          ]),
          "cell-diagnostic_uplink": _withCtx(({ row: bin }) => [
            _createElementVNode("div", _hoisted_12, [
              (bin.diagnostic_uplink)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_router_link, {
                      to: `/provisioning/devices/uplink/${bin.diagnostic_uplink}`
                    }, {
                      default: _withCtx(() => [
                        (_ctx.permissions.includes('device-uplink'))
                          ? (_openBlock(), _createElementBlock("a", _hoisted_14, _cache[26] || (_cache[26] = [
                              _createElementVNode("div", {
                                class: _normalizeClass(`badge badge-light-success`)
                              }, [
                                _createElementVNode("i", { class: "fas fa-link text-primary" })
                              ], -1 /* HOISTED */)
                            ])))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    (_ctx.permissions.includes('device-uplink'))
                      ? (_openBlock(), _createElementBlock("a", _hoisted_16, _cache[27] || (_cache[27] = [
                          _createElementVNode("div", {
                            class: _normalizeClass(`badge badge-light-secondary`)
                          }, [
                            _createElementVNode("i", { class: "fas fa-link text-muted" })
                          ], -1 /* HOISTED */)
                        ])))
                      : _createCommentVNode("v-if", true)
                  ])),
              (_ctx.permissions.includes('delete_bin'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 2,
                    href: "#",
                    class: "text-gray-600 text-hover-primary ms-2",
                    title: "Delete",
                    onClick: ($event: any) => (_ctx.deleteDevice(bin.variant_id))
                  }, _cache[28] || (_cache[28] = [
                    _createElementVNode("div", {
                      class: _normalizeClass(`badge badge-light-success`)
                    }, [
                      _createElementVNode("i", { class: "fas fa-trash text-danger" })
                    ], -1 /* HOISTED */)
                  ]), 8 /* PROPS */, _hoisted_17))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange", "onSelectAll"])
      ]),
      _createCommentVNode("end::Card body")
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _cache[33] || (_cache[33] = _createElementVNode("div", { class: "modal-header bg-primary" }, [
            _createElementVNode("span", {
              class: "modal-title text-white text-uppercase h5 text-primary",
              id: "exampleModalLabel"
            }, " Update Bin Offset "),
            _createElementVNode("div", {
              class: "btn btn-sm",
              "data-bs-dismiss": "modal",
              id: "offsetclosemodal"
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ])
          ], -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _cache[30] || (_cache[30] = _createElementVNode("label", {
                for: "exampleFormControlInput1 ",
                class: "form-label"
              }, "Offset Raw distance", -1 /* HOISTED */)),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                class: "form-control my-3",
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.payload.offset_raw_distance) = $event)),
                id: "exampleFormControlInput1"
              }, null, 512 /* NEED_PATCH */), [
                [_vModelText, _ctx.payload.offset_raw_distance]
              ])
            ]),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.payload.apply_offset_raw_distance) = $event)),
              class: "form-select my-3",
              "aria-label": "Default select example"
            }, _cache[31] || (_cache[31] = [
              _createElementVNode("option", { selected: "" }, "Open this select menu", -1 /* HOISTED */),
              _createElementVNode("option", { value: "1" }, "Enable", -1 /* HOISTED */),
              _createElementVNode("option", { value: "0" }, "Disable", -1 /* HOISTED */)
            ]), 512 /* NEED_PATCH */), [
              [_vModelSelect, _ctx.payload.apply_offset_raw_distance]
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _cache[32] || (_cache[32] = _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              "data-bs-dismiss": "modal"
            }, " Close ", -1 /* HOISTED */)),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.offs(_ctx.payload)))
            }, " Save changes ")
          ])
        ])
      ])
    ]),
    _createCommentVNode(" Bins Routing Configuration Modal "),
    _createElementVNode("div", _hoisted_24, [
      _createCommentVNode("begin::Modal dialog"),
      _createElementVNode("div", _hoisted_25, [
        _createCommentVNode("begin::Modal content"),
        _createElementVNode("div", _hoisted_26, [
          _createCommentVNode("begin::Modal header"),
          _createElementVNode("div", _hoisted_27, [
            _createCommentVNode("begin::Modal title"),
            _cache[35] || (_cache[35] = _createElementVNode("span", {
              class: "modal-title h5 text-uppercase text-white",
              id: "exampleModalLabel",
              style: {"margin-left":"1.1rem"}
            }, " Routing Configuration ", -1 /* HOISTED */)),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.modalClose())),
              class: "btn btn-icon btn-sm",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "binsmodal"
            }, _cache[34] || (_cache[34] = [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              }, null, -1 /* HOISTED */)
            ])),
            _createCommentVNode("end::Close")
          ]),
          _createCommentVNode("end::Modal header"),
          _createCommentVNode("begin::Form"),
          _createVNode(_component_Form, {
            id: "kt_modal_enable_routing_form",
            class: "form",
            onSubmit: _ctx.submit
          }, {
            default: _withCtx(() => [
              _createCommentVNode("begin::Modal body"),
              _createElementVNode("div", _hoisted_28, [
                _createCommentVNode("begin::Scroll"),
                _createElementVNode("div", _hoisted_29, [
                  _createCommentVNode("end::Input group"),
                  _createCommentVNode(" Row "),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_32, [
                        _createCommentVNode("begin::Label"),
                        _cache[36] || (_cache[36] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2" }, "Routing Weight Limit", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "routing_weight_limit",
                          placeholder: "weight limit",
                          modelValue: _ctx.config.routing_weight_limit,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.config.routing_weight_limit) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_34, [
                        _createCommentVNode("begin::Label"),
                        _cache[37] || (_cache[37] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2" }, "Routing Cost for Emptying", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "routing_cost_for_emptying",
                          placeholder: "routing cost",
                          modelValue: _ctx.config.routing_cost_for_emptying,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.config.routing_cost_for_emptying) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_36, [
                        _createCommentVNode("begin::Label"),
                        _cache[38] || (_cache[38] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2" }, "Routing Service Time", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "routing_service_time",
                          placeholder: "service time",
                          modelValue: _ctx.config.routing_service_time,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.config.routing_service_time) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_37, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_38, [
                        _createCommentVNode("begin::Label"),
                        _cache[39] || (_cache[39] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2" }, "Routing Volume", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "routing_volume",
                          placeholder: "service time",
                          modelValue: _ctx.config.routing_volume,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.config.routing_volume) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_40, [
                        _createCommentVNode("begin::Label"),
                        _cache[40] || (_cache[40] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2" }, "Pickup Threshold", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "form-control form-control-solid border border-primary",
                          rows: "3",
                          name: "pickup_threshold",
                          placeholder: "service time",
                          modelValue: _ctx.config.pickup_threshold,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.config.pickup_threshold) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]),
                    _createElementVNode("div", _hoisted_41, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_42, [
                        _createCommentVNode("begin::Label"),
                        _cache[42] || (_cache[42] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Enable/Disable Routing", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          name: "routing_enabled",
                          "data-control": "select2",
                          "data-hide-search": "true",
                          "data-placeholder": "enable/disable routing...",
                          class: "form-select form-select-solid border border-primary",
                          as: "select",
                          modelValue: _ctx.config.routing_enabled,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.config.routing_enabled) = $event))
                        }, {
                          default: _withCtx(() => _cache[41] || (_cache[41] = [
                            _createElementVNode("option", { value: "" }, "enable/disable routing...", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "1" }, "Enable Routing", -1 /* HOISTED */),
                            _createElementVNode("option", { value: "0" }, "Disable Routing", -1 /* HOISTED */)
                          ])),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ])
                  ])
                ])
              ]),
              _createCommentVNode("begin::Modal footer"),
              _createElementVNode("div", _hoisted_43, [
                _createCommentVNode("begin::Button"),
                _cache[44] || (_cache[44] = _createElementVNode("button", {
                  type: "reset",
                  id: "kt_modal_create_api_key_cancel",
                  class: "btn btn-white me-3"
                }, " Clear ", -1 /* HOISTED */)),
                _createCommentVNode("end::Button"),
                _createCommentVNode("begin::Button"),
                _createElementVNode("button", _hoisted_44, _cache[43] || (_cache[43] = [
                  _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1 /* HOISTED */),
                  _createElementVNode("span", { class: "indicator-progress" }, [
                    _createTextVNode(" Please wait... "),
                    _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                  ], -1 /* HOISTED */)
                ]), 512 /* NEED_PATCH */),
                _createCommentVNode("end::Button")
              ]),
              _createCommentVNode("end::Modal footer")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onSubmit"])
        ])
      ])
    ], 512 /* NEED_PATCH */),
    _createCommentVNode(" End Bins Routing Configuration Modal "),
    _createCommentVNode("end::Card")
  ], 64 /* STABLE_FRAGMENT */))
}