
import { defineComponent, ref, onMounted } from "vue";
import { Field, Form, useResetForm, FormActions } from "vee-validate";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  getCustomersList,
  getBinsListing,
  enable_depth_Map,
  enable_mobility,
  enableNotification,
  wasteType_list,
  update_bin_dimension,
  update_bin_waste_type,
  update_bin_location,
  update_bin_offset,
  update_bin_full_at,
  deleteDeviceFromPlatform,
  binsConfiguration,
  zones_list,
  assignZone,
} from "@/store/api/devices";
import moment, { localeData } from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { config } from "vuex-module-decorators";
interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
  };
}
export default defineComponent({
  name: "repository",
  components: {
    KTDatatable,
    Field,
    Form,
  },
  setup() {
    const data = ref<any[]>([]);
    const waste_type_list = ref<any>();
    const zones = ref<any>();
    const bindata = ref<any[]>([]);
    const customers = ref<any[]>([]);
    const bin_ids = ref<any[]>([]);
    const loading = ref<boolean>(true);
    const payload = ref({
      bin_id: "",
      apply_offset_raw_distance: "",
      offset_raw_distance: "",
    });
    const config = ref({
      bin_id: {},
      routing_weight_limit: 2000,
      routing_cost_for_emptying: 5,
      routing_service_time: 120,
      routing_volume: 1000,
      routing_enabled: 1,
      pickup_threshold: 0.75,
    });
    const search = ref<string>("");
    const cardData = ref<string>("");
    const permissions = ref<any>("");
    const headerConfig = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        name: "Variant Id",
        key: "variant_id",
      },
      {
        name: "Company",
        key: "se_account_id",
      },
      {
        name: "Last Seen",
        key: "last_seen",
      },
      {
        name: "Action",
        key: "diagnostic_uplink",
      },
    ]);
    const total = ref<number>(0);
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
      },
    });

    onMounted(async () => {
      permissions.value = localStorage.getItem("permissions");
      permissions.value = JSON.parse(permissions.value);
      customers.value = await getCustomersList();
      console.log("loading data", loadData());
      setCurrentPageBreadcrumbs("Bins", [""]);
    });
    const loadData = async () => {
      loading.value = true;
      let value = await wasteType_list();
      waste_type_list.value = value.data;
      let binValue = await getBinsListing(pagination.value);
      bindata.value = binValue.devices;
      total.value = binValue.total_number_of_records;
      // let zonesValue= await zones_list(selectedAccountId);
      // zones.value=zonesValue.data;
      loading.value = false;
    };
    const dateTime = (value) => {
       const date = new Date(value);

      // Define options for formatting the date
      const options = {
        weekday: 'short',   // Tue
        year: 'numeric',    // 2024
        month: 'short',     // Oct
        day: '2-digit',     // 01
        hour: '2-digit',    // 13
        minute: '2-digit',  // 12
        second: '2-digit',  // 01
        hour12: false,      // 24-hour clock
      };

      // Format the date using toLocaleDateString and toLocaleTimeString
      return date.toLocaleDateString('en-US', options)
    };

    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };
    const onCustomerChange = (event) => {
      pagination.value.page_number = 1;
      pagination.value.customer_id = event.target.value;
      loadData();
    };

    const getCustomerName = (val) => {
      let name = customers.value.filter((x) => (x.id = val))[0].name;
      return name;
    };

    const searchItems = () => {
      pagination.value.page_number = 1;
      if (search.value !== "") {
        pagination.value.filters.name = search.value;
      } else {
        pagination.value.filters.name = "";
      }
      loadData();
    };

    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };

    //Ena & Dis depth map
    const Depthmapoption = () => {
      Swal.fire({
        title: "Depth Map",
        input: "select",
        inputOptions: {
          1: "Enable Depth Map",
          0: "Disable Depth Map",
        },
        inputPlaceholder: "Select Option",
        showCancelButton: true,
      }).then(async (result) => {
        const res = await enable_depth_Map({
          bin_id: Object.values(bin_ids.value),
          is_depth_map: result.value,
        });
        console.log(res);
      });
    };
    // Notifications
    const BinNotifications = () => {
      Swal.fire({
        title: "Bins Notification",
        input: "select",
        inputOptions: {
          1: "Enable Notification",
          0: "Disable Notification",
        },
        inputPlaceholder: "Select Option",
        showCancelButton: true,
      }).then(async (result) => {
        const res = await enableNotification({
          bin_id: Object.values(bin_ids.value),
          is_notification_enabled: result.value,
        });
        console.log(res);
      });
    };

    //Update mobility options
    const Mobilityoption = () => {
      Swal.fire({
        title: "Mobility",
        input: "select",
        inputOptions: {
          1: "Enable Mobility",
          0: "Disable Mobility",
        },
        inputPlaceholder: "Select Option",
        showCancelButton: true,
      }).then(async (result) => {
        const res = await enable_mobility({
          bin_id: Object.values(bin_ids.value),
          is_mobility: result.value,
        });

        console.log(res);
      });
    };
    //Update offset
    const offs = async (data) => {
      const resp = await update_bin_offset({
        bin_id: Object.values(bin_ids.value),
        apply_offset_raw_distance: data.apply_offset_raw_distance,
        offset_raw_distance: data.offset_raw_distance,
      });
      loadData();
      document.getElementById("offsetclosemodal")?.click();
      console.log(resp);
    };

    // Bins Configuration for Routing

    const submit = async () => {
      const bin_id = Object.values(bin_ids.value);
      console.log("bins ids", bin_id);
      config.value.bin_id = Object.values(bin_ids.value);
      console.log("Data from Routing Config", config.value);
      const resp = binsConfiguration(config.value)
        .then((resp) => {
          Swal.fire({
            icon: "success",
            title: `${resp.message}`,
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        });
      modalClose();
      loadData();
      document.getElementById("binsmodal")?.click();
    };
    const modalClose = () => {
      bin_ids.value = [];
      config.value = {
        bin_id: "",
        routing_weight_limit: 2000,
        routing_cost_for_emptying: 5,
        routing_service_time: 120,
        routing_volume: 1000,
        routing_enabled: 1,
        pickup_threshold: 0.75,
      };
    };

    //Update bins dimension values
const BinDimensionsin = () => {
  Swal.fire({
    showClass: {
      popup: "animate__animated animate__fadeInDown",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutUp",
    },
    title: "Bin Dimensions",
    html:
      "<div><p>Length:</p>" +
      '<input id="swal-input1" class="swal2-input">' +
      "<p id='length-error' style='color: red; display: none;'>Bin Length must be greater than 0 and less than or equal to 500.</p>" +
      "<p>Width:</p>" +
      '<input id="swal-input2" class="swal2-input">' +
      "<p id='width-error' style='color: red; display: none;'>Bin Width must be greater than 0 and less than or equal to 500.</p>" +
      "<p>Height:</p>" +
      '<input id="swal-input3" class="swal2-input">' +
      "<p id='height-error' style='color: red; display: none;'>Bin Height must be greater than 0 and less than or equal to 500.</p>", // Error message placeholders
    customClass: "swal-height",
    showCancelButton: true,
    confirmButtonText: "Confirm",
    showLoaderOnConfirm: true,
    preConfirm: () => {
      const base_length = Swal.getPopup().querySelector("#swal-input1").value;
      const base_width = Swal.getPopup().querySelector("#swal-input2").value;
      const bin_height = Swal.getPopup().querySelector("#swal-input3").value;

      // Error message elements
      const lengthErrorElement = Swal.getPopup().querySelector("#length-error");
      const widthErrorElement = Swal.getPopup().querySelector("#width-error");
      const heightErrorElement = Swal.getPopup().querySelector("#height-error");

      let valid = true;

      // Validate Length
      if (base_length <= 0 || base_length > 500) {
        lengthErrorElement.style.display = "block";  // Show error message for length
        valid = false;
      } else {
        lengthErrorElement.style.display = "none";  // Hide error message for length
      }

      // Validate Width
      if (base_width <= 0 || base_width > 500) {
        widthErrorElement.style.display = "block";  // Show error message for width
        valid = false;
      } else {
        widthErrorElement.style.display = "none";  // Hide error message for width
      }

      // Validate Height
      if (bin_height <= 0 || bin_height > 500) {
        heightErrorElement.style.display = "block";  // Show error message for height
        valid = false;
      } else {
        heightErrorElement.style.display = "none";  // Hide error message for height
      }

      // If validation fails, prevent further actions
      if (!valid) {
        return false;
      }

      // If validation passes, return the data
      return {
        base_length: base_length,
        base_width: base_width,
        bin_height: bin_height,
      };
    },
  }).then(async (result) => {
    let ids: (string | number)[] = []; 
    bin_ids.value.map((item)=>{
      ids.push(item.bin_id)
    })
    if (result.value) {
      const res = await update_bin_dimension({
        bin_id: ids,
        base_length: result.value.base_length,
        base_width: result.value.base_width,
        bin_height: result.value.bin_height,
      });
      console.log(res);
    }
  });
};
    //Update bins location values
    const BinLocation = () => {
      Swal.fire({
        title: "Bin Locations",
        html:
          "<div><p>Address:</p>" +
          '<input id="swal-input11" class="swal2-input">' +
          "<p>lat:</p>" +
          '<input id="swal-input22" class="swal2-input">' +
          "<p>lng:</p>" +
          '<input id="swal-input33" class="swal2-input">',
        customClass: "swal-height",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const address = Swal.getPopup().querySelector("#swal-input11").value;
          const lat = Swal.getPopup().querySelector("#swal-input22").value;
          const lng = Swal.getPopup().querySelector("#swal-input33").value;
          return {
            address: address,
            lat: lat,
            lng: lng,
          };
        },
      }).then(async (result) => {
        console.log(result.value);

        if (result.isConfirmed) {
          let binIdArray = [];
          bin_ids.value.map((item)=>{
            binIdArray.push(item.bin_id)
          })
          const resp = await update_bin_location({
            bin_id: binIdArray,
            address: result.value.address,
            lat: result.value.lat,
            lng: result.value.lng,
          });
          console.log(resp);
        }
      });
    };
    //Update bins full_at values
    const BinFull_At = () => {
      Swal.fire({
        title: "Bin Full At",
        html:
          "<div><p>Full At:</p>" +
          '<input id="swal-input11" class="swal2-input">',
        customClass: "swal-height",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          const full_at = Swal.getPopup().querySelector("#swal-input11").value;

          return {
            full_at: full_at,
          };
        },
      }).then(async (result) => {
        console.log(result.value);

        if (result.isConfirmed) {
          console.log(result.value);
          const resp = await update_bin_full_at({
            bin_id: Object.values(bin_ids.value),
            full_at: result.value.full_at,
          });
          loadData();
          console.log(resp);
        }
      });
    };
    const selectAll = (val) => {
      console.log("hit the values *******");
  if (val === true) {
    bindata.value.forEach((x) => {
      // Push both bin_id and se_account_id
      if (!bin_ids.value.some((bin) => bin.bin_id === x.bin_id)) {
        bin_ids.value.push({ bin_id: x.bin_id, se_account_id: x.se_account_id });
      }
    });
  } else {
    // Remove all bins that are currently selected
    bin_ids.value = bin_ids.value.filter(
      (d) => !bindata.value.find((rm) => rm.bin_id === d.bin_id)
    );
  }
};
    const isChecked = (bin_id) => {
  // Check if the bin_id is in the selected bins
  return bin_ids.value.some((bin) => bin.bin_id === bin_id);
};

    // const unCheck = (val) => {
    //   bin_ids.value.splice(
    //     bin_ids.value.findIndex((a) => a === val),
    //     1
    //   );
    // };
    const unCheck = (bin_id) => {
  // Filter out the bin by its bin_id
  bin_ids.value = bin_ids.value.filter((bin) => bin.bin_id !== bin_id);
};
    const BinWasteTyep = () => {
      var model: any = [];
      Object.keys(waste_type_list.value).forEach(function (key) {
        model[key] = waste_type_list.value[key].name;
      });

      Swal.fire({
        title: "Waste Type",
        input: "select",
        inputOptions: model,
        showCancelButton: true,
        inputPlaceholder: "Select a option",
      }).then(async (inputValue) => {
        if (inputValue.isConfirmed === true) {
          const res = await update_bin_waste_type({
            bin_id: Object.values(bin_ids.value),
            waste_type_id: inputValue.value,
          });
          console.log(res);
        }
      });
    };
    const Zones = async () => {
  if (!allBinsHaveSameAccountId()) {
    Swal.fire({
      icon: "warning",
      title: "Multiple bins with different accounts selected",
      text: "Please select bins with the same account to view zones.",
    });
    return;
  }

  // Fetch zones for the selected se_account_id
  const selectedAccountId = bin_ids.value[0].se_account_id; // Assuming all bins have the same se_account_id
  let zonesValue = await zones_list(selectedAccountId);
  zones.value = zonesValue.data;

  // Create a model for the zones dropdown
  const zoneOptions = Object.keys(zones.value)
    .map((key) => `<option value="${key}">${zones.value[key].name}</option>`)
    .join("");

  Swal.fire({
    title: "Zone Selection",
    width:900,
    heigth: 300,
    html: `
      <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px;">
        <!-- Left Column: Bin Height and Routing Volume -->
        <div>
          <p>Bin Height:</p>
          <input  id="binHeight" class="swal2-input" style="width: 100%;" placeholder="Enter Bin Height" />
          
          <p>Routing Volume:</p>
          <input  id="routingVolume" class="swal2-input" style="width: 100%;" placeholder="Enter Routing Volume" />
        </div>

        <!-- Right Column: Pickup Threshold and Zone Selection -->
        <div>
          <p>Pickup Threshold:</p>
          <input  id="pickupThreshold" class="swal2-input" style="width: 100%;" placeholder="Enter Pickup Threshold" />
          
          <p>Select a Zone:</p>
          <select id="zoneSelect" class="swal2-input" style="width: 100%;">
            <option value="" disabled selected>Select a zone</option>
            ${zoneOptions}
          </select>
        </div>
      </div>
      <div id="zoneDetails" style="margin-top: 20px;"></div>
    `,
    showCancelButton: true,
    preConfirm: async () => {
      // Fetch the selected zone key
      const selectedZoneKey = document.getElementById("zoneSelect").value;
      const binHeight = document.getElementById("binHeight").value;
      const routingVolume = document.getElementById("routingVolume").value;
      const pickupThreshold = document.getElementById("pickupThreshold").value;

      // Validate user inputs
      if (!selectedZoneKey) {
        Swal.showValidationMessage(`Please select a zone`);
        return;
      }
      if (!binHeight || !routingVolume || !pickupThreshold) {
        Swal.showValidationMessage(`Please fill all the bin details`);
        return;
      }

      const selectedZone = zones.value[selectedZoneKey];

      // Now use selectedZone.id for zone_id
      const payload = {
        bin_id: bin_ids.value.map(bin => Number(bin.bin_id)), // Convert bin_id to numbers
        zone_id: Number(selectedZone.id), // Use the zone's id from the selected zone details
        bin_height: Number(binHeight), // Include bin_height in the payload
        routing_volume: Number(routingVolume), // Include routing_volume in the payload
        pickup_threshold: Number(pickupThreshold), // Include pickup_threshold in the payload
      };

      // Hit the API to assign the zone to the selected bins
      try {
        const response = await assignZone(payload);
        Swal.fire({
          title: "Success",
          text: "Zone assigned successfully!",
          icon: "success",
        });
        console.log(response); // Handle the response if needed
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Failed to assign the zone.",
          icon: "error",
        });
        console.error(error);
      }
    },
  });

  // Add an event listener to the select dropdown for zone changes
  const zoneSelect = document.getElementById("zoneSelect");
  zoneSelect.addEventListener("change", (event) => {
    const selectedZoneKey = event.target.value;
    const selectedZone = zones.value[selectedZoneKey];

    // Display zone details dynamically in the modal
    document.getElementById("zoneDetails").innerHTML = `
  <div style="display: grid; grid-template-columns: repeat(4, 1fr); text-align: center; margin-top: 10px;">
    <!-- Headings in one row -->
    <div><strong>Name</strong></div>
    <div><strong>Radius</strong></div>
    <div><strong>Latitude</strong></div>
    <div><strong>Longitude</strong></div>

    <!-- Values in the next row -->
    <div>${selectedZone.name}</div>
    <div>${selectedZone.radius} meters</div>
    <div>${selectedZone.center_lat}</div>
    <div>${selectedZone.center_long}</div>
  </div>
`;
  });
};


// Example function for hitting the assignZone API



const allBinsHaveSameAccountId = () => {
  if (bin_ids.value.length === 0) return false; // No bins selected
  console.log("bin_idsbin_idsbin_ids", bin_ids.value);

  const firstAccountId = bin_ids.value[0].se_account_id;
  return bin_ids.value.every((bin) => bin.se_account_id === firstAccountId);
};
const selectBins = (val) => {
  console.log("valvalvalval", val);

  const isSelected = isChecked(val.bin_id);

  if (!isSelected) {
    bin_ids.value.push({ bin_id: val.bin_id, se_account_id: val.se_account_id });
     console.log("hello check ***",bin_ids.value)
  } else {
    unCheck(val.bin_id);
  }
};
    const deleteDevice = async (val) => {
      Swal.fire({
        title: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const resp = await deleteDeviceFromPlatform(val);
          console.log(resp);
          Swal.fire({
            text: "Device has been Deleted successfully!",
            icon: "success",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          loadData();
        }
      });
    };

    return {
      data,
      bindata,
      headerConfig,
      customers,
      total,
      loading,
      search,
      pagination,
      cardData,
      currentChange,
      searchItems,
      getCustomerName,
      itemsPerPageChange,
      onCustomerChange,
      dateTime,
      Depthmapoption,
      BinNotifications,
      Mobilityoption,
      BinDimensionsin,
      BinWasteTyep,
      Zones,
      BinLocation,
      BinFull_At,
      bin_ids,
      selectBins,
      allBinsHaveSameAccountId,
      selectAll,
      isChecked,
      payload,
      offs,
      deleteDevice,
      permissions,
      submit,
      modalClose,
      config,
    };
  },
});
